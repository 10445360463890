<!--todo 停用功能后端方法无法实现 -->
<template>
    <div class="PointGoods" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select placeholder="请选择" v-model="form.deptGroupCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="bd in meta.deptGroup"
                                    :label="bd.name"
                                    :value="bd.code"
                                    :key="bd.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商品状态">
                            <el-select placeholder="商品状态" v-model="form.status">
                                <el-option label="请选择" value="" />
                                <el-option label="启用" value="1" />
                                <el-option label="停用" value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商品类型">
                            <el-select placeholder="商品类型" v-model="form.goodsType">
                                <el-option label="请选择" value="" />
                                <el-option label="折扣券" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="开始日期">
                            <el-date-picker
                                type="date"
                                v-model="form.startTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结束日期">
                            <el-date-picker
                                v-model="form.endTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.member.pointGoods.open')"
                >查询</el-button
            >
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.member.pointGoods.create')"
                >新建</el-button
            >
            <el-button type="primary" @click="handleOn" size="small" v-if="hasPrivilege('menu.member.pointGoods.edit')"
                >批量启用</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.member.pointGoods.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="企业" width="200" prop="companyName" v-if="showColumn('companyName')" />
                <el-table-column
                    label="机构组名称"
                    width="140"
                    prop="deptGroupName"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column label="商品名称" width="140" prop="name" v-if="showColumn('name')" />
                <el-table-column
                    label="商品类型"
                    width="80"
                    prop="goodsType"
                    v-if="showColumn('goodsType')"
                    key="goodsType"
                >
                    <template slot-scope="scope"
                        ><span>{{ scope.row.goodsType == 1 ? '折扣券' : 0 }}</span></template
                    >
                </el-table-column>
                <el-table-column
                    label="有效时间"
                    width="150"
                    prop="effectiveTime"
                    v-if="showColumn('effectiveTime')"
                    key="effectiveTime"
                >
                    <template slot-scope="scope"
                        ><span>{{ scope.row.effectiveTime.slice(0, 10) }}</span></template
                    >
                </el-table-column>
                <el-table-column label="购买积分" width="80" prop="buyPoint" v-if="showColumn('buyPoint')" />
                <el-table-column label="库存数量" width="80" prop="stock" v-if="showColumn('stock')" />
                <el-table-column label="商品状态" width="80" prop="status" v-if="showColumn('status')">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status == 1 ? '启用' : '停用' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="150" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建日期" width="180" prop="createTime" v-if="showColumn('createTime')" />

                <el-table-column
                    label="操作"
                    min-width="350"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.member.pointGoods.open')"
                            >查看</el-button
                        >
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.member.pointGoods.edit')"
                            >编辑</el-button
                        >
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.member.pointGoods.delete')"
                            >删除</el-button
                        >
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handleOn(scope.row)"
                            v-if="hasPrivilege('menu.member.pointGoods.edit')"
                            >启用</el-button
                        >
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleOff(scope.row)"
                            v-if="hasPrivilege('menu.member.pointGoods.edit')"
                            >停用</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'PointGoods',
    components: { CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],

    data() {
        return {
            form: {
                search: '',
                status: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            meta: {
                deptGroup: [],
            },
            loading: false,
            tableData: [],
            url: {
                page: '/member/pointGoods/page',
                handleOn: '/member/pointGoods/enable',
                handleOff: '/member/pointGoods/disable',
                delete: '/member/pointGoods/delete',
            },
        };
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '停用';
            }
            if (status == '1') {
                return '启用';
            }
        },
    },
    mounted() {
        this.inits();
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        inits() {
            const _this = this;
            UrlUtils.DeptGroup(_this, (rst) => {
                _this.meta.deptGroup = rst;
            });
        },
        handleDelete(row) {
            if (row.status != 2) {
                this.$message.error('非停用单据不能删除');
            } else {
                UrlUtils.DeleteRemote(this, this.url.delete, row.code);
            }
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '积分商品', '/member/pointGoods/export', this.form, codes);
        },

        handleOn(row) {
            const _this = this;
            if (row.code) {
                const _params = { codes: row.code };
                UrlUtils.PatchRemote(_this, _this.url.handleOn, _params, null, () => {
                    this.$message.success('保存成功');
                    _this.handleQuery();
                });
            } else {
                const _a = [];
                let vaildStatus = false;
                if (_this.$refs['table'].selection == 0) {
                    _this.$message.error('请选择启用单据');
                    return;
                }
                _this.$refs['table'].selection.forEach((v) => {
                    if (v.status != 2) {
                        vaildStatus = true;
                    }
                    _a.push(v.code);
                });
                if (vaildStatus) {
                    _this.$message.error('请选择停用状态单据进行启用');
                    return;
                }
                const _params = { codes: _a };
                UrlUtils.PatchRemote(_this, _this.url.handleOn, _params, null, () => {
                    this.$message.success('保存成功');
                    _this.handleQuery();
                });
            }
        },
        handleOff(row) {
            const _this = this;
            const _params = { code: row.code };
            UrlUtils.PatchRemote(_this, _this.url.handleOff, _params, null, () => {
                this.$message.success('保存成功');
                _this.handleQuery();
            });
        },
        handleCreate() {
            Util.nameJump(this, 'menu.member.pointGoods.create', ['会员管理', '积分商品', '新建积分商品']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.member.pointGoods.detail', ['会员管理', '积分商品', '积分商品详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.member.pointGoods.edit', ['会员管理', '积分商品', '编辑积分商品'], {
                form: row,
                code: row.code,
            });
        },
    },
};
</script>

<style scoped>
.PointGoods .el-form-item {
    margin-bottom: 0;
}
</style>
